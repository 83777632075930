import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import { StaticImage } from "gatsby-plugin-image";

const pageDescription =
  "Providing tailor-made funding solutions ranging from R50 000 to R5 000 000 to SMEs who have a viable business and require finance for expansion or working capital.";
const pagesBreadcrumb = [
  {
    name: "About",
    to: "/about",
  },
];

const AboutPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Introducing Nora Finance"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <div className="overflow-hidden">
        <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
          <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
            <div>
              <h2 className="text-lg font-semibold text-indigo-600">About</h2>
              <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Meet Nora Finance
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:col-start-2 lg:row-start-1">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <StaticImage
                      quality={100}
                      className="rounded-lg  shadow-lg"
                      src="../images/about-1.png"
                      alt="Two people at a conference room talking"
                      width={1184}
                      height={1376}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="mx-auto max-w-prose text-base lg:max-w-none">
                <p className="text-lg text-gray-500">
                  We provide tailor-made funding solutions ranging from R50 000
                  to R5 000 000 to SMEs who have a viable business and require
                  finance for expansion or working capital.
                </p>
              </div>
              <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
                <p>
                  SMEs are often in a position where they are not able to
                  fulfill customers' orders because of being unable to secure
                  the cash required to buy material. This often results in the
                  business losing both the order and the customer.
                </p>
                <br />
                <p>
                  Our Invoice Discounting and Purchase Order Financing products
                  are designed to bridge this gap and have the advantage of
                  being faster and simpler to obtain than a traditional bank
                  loan.
                </p>
                <br />
                <ul role="list" className="list-inside list-disc">
                  <li>Insight</li>
                  <li>Action</li>
                </ul>
                <br />
                <h3 className="mb-2 text-xl font-bold">
                  What makes us different?
                </h3>
                <p>
                  Our experience as vendors gives us a deep understanding of our
                  clients and often allowing us to anticipate their needs.
                </p>
                <br />
                <p>
                  Our dedication to excellent client service is evident in our
                  simplified application process which ensures that our clients
                  are not caught in laborious admin requests.
                </p>
                <br />
                <p>
                  For example, our photo-scan process through which in lieu of a
                  scanner, clients can scan documents with their mobile phones
                  or fill in our electronic form.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default AboutPage;

export const Head: HeadFC = () => (
  <CustomHead title="About" description={pageDescription} />
);
